import styled from "styled-components";
import { ChangeEvent } from "react";

const InputForm = styled.div`
  margin-top: 20px;
`;
const InputLabel = styled.div`
  color: #515251;  
  font-size: 14px;
`;

const InputWrapper = styled.div`
  display:flex;
  flex-direction: column;
  gap: 15px;
`;

const InputText = styled.input<InputTextProps>`
  width: ${(props) => (props.width ? props.width + "px"  : "100%" )};
  height: 45px;
  box-sizing: border-box;
  font-size: 14px;
  border: 1px solid #ebebeb;
  padding-left: 10px;
  border-radius: 5px;
  margin-top: 5px;  
  &:focus {
    outline: none;
  }
`;

interface InputTextProps {
  label?: string;
  type: string;
  placeholder?: string;
  width?: number;
  onChildStateChange?: any;
}

const InputTextComponent: React.FC<InputTextProps> = ({
  label,
  type,
  placeholder,
  width,
  onChildStateChange
}) => {
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      onChildStateChange(value);
    };
  return(<>
    <InputForm>
      {label ? <InputLabel>{label}</InputLabel> : null}
      <InputWrapper>        
        <InputText         
          width={width}
          type={type}
          placeholder={placeholder}
          onChange={handleInputChange}
        />
      </InputWrapper>
    </InputForm>
  </>);
}

export default InputTextComponent;
