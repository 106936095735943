import styled from "styled-components"

const ColoredBox = styled.div<ColorProps>`
    width: 30px;
    height: 30px;
    border-radius: 5px;
    text-align: center;
    background-color: ${props => props.color};
    margin-right: 10px;
    color: white;
    font-size: 18px;
    display:flex;
    align-items: center;
    justify-content: center;
`;

const BoxWrapper = styled.div`
    display: flex;
    font-size: 18px;
    font-weight: 400;
`;

interface ColorProps {
    color: string;
}

const BoxText = styled.div`
   padding-top: 7px;
    color: #424042;
    font-size: 16px;
    -webkit-letter-spacing: 1px;
    -moz-letter-spacing: 1px;
    -ms-letter-spacing: 1px;
    letter-spacing: 0px;
    cursor: pointer;
`;

interface InputTextProps{
    symbol: string;
    text: string;
    color: string;
    onClick?: React.MouseEventHandler;
 }

const IconTextComponent:React.FC<InputTextProps> = ({ symbol, text, color, onClick })=> (
  <>
    <BoxWrapper>
        <ColoredBox color={color}>{symbol}</ColoredBox>
        <BoxText onClick={onClick}>{text}</BoxText>
    </BoxWrapper>
  </>
)

export default IconTextComponent;
