import * as React from "react";
import styled from "styled-components";
import  SearchBoxComponent from '../../components/SearchBox/SearchBox.component';

const Wrapper = styled.div`
display: flex;
flex-direction: row;
gap: 25px;
padding-top: 20px;
width: 100%;
flex-wrap: wrap;
`;

const Header = styled.div`
&{
  text-align:center;  
  letter-spacing: 0;
  color: #333;
  padding-bottom: 10px;
  font-size: 24px;
}
`;

interface Props {
  showSearch?: boolean;
  title?: string;
  children: React.ReactNode
}
const Team: React.FunctionComponent<Props> = ({ showSearch, title, children }) => {
  return (
    <>
      {showSearch ? <SearchBoxComponent placeholder="Search by name..." /> : null }
      {title ? <Header>{title}</Header> : null }           
      <Wrapper>       
        {children}
      </Wrapper>
    </>
  );
};

export default Team;
