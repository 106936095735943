import * as React from "react";
import './styles/team-person.css';
import { InitialNamesUtil } from "@rocket-desert/common-lib";

interface Props {
  firstName: string;
  lastName: string;
  position: string;
}
const TeamPerson: React.FunctionComponent<Props> = ({
  firstName,
  lastName,
  position,
}) => {
  return (
    <>      
        <div className="column">
          <div className="card">
            <div className="img-container">
              {InitialNamesUtil(firstName, lastName, false)}
            </div>
            <div className="container">
              <h3>  {firstName} {lastName} </h3>
              <p className="position-title">{position}</p>
            </div>
          </div>
        </div>      
    </>
  );
};

export default TeamPerson;
