import styled from "styled-components"

const Button = styled.div<{bgColor?: string, txtColor?: string}>`
    min-width: 100px;
    width: fit-content;
    padding: 10px 7px;
    border-radius: 5px;
    background-color: #2a7aeb;
    color: #ffffff;
    text-align: center;    
    font-size: 14px;    
    cursor: pointer;
    background-color: ${props => props.bgColor ? props.bgColor : '#e3e1e1'};
    color: ${props => props.txtColor ? props.txtColor : '#3b3a39'};
`;

interface Props{
    text: string;
    color?: string;
    textColor?: string;
    onClick?: React.MouseEventHandler;
 }

const CreateButtonComponent:React.FC<Props> = ({ text, color, textColor, onClick })=> (
  <>
    <Button onClick={onClick} bgColor={color} txtColor={textColor}>
       { text }
    </Button>
  </>
)

export default CreateButtonComponent;