import styled from "styled-components"

const SearchInput = styled.input`
    width: 200px;
    border: 1px solid #f2edf2;
    padding: 8px;
    border-radius: 0px;
    &:focus {
        width: 300px;
        outline: none;
    }
`;

interface SearchBoxProps{
    placeholder?: string;
 }

const SearchBoxComponent:React.FC<SearchBoxProps> = ({ placeholder })=> (
  <>
    <SearchInput placeholder={placeholder} />
  </>
)

export default SearchBoxComponent;
