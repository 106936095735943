import styled from 'styled-components';

const LogoTitle = styled.h1`
letter-spacing: -1.5px;
    padding: 0px;
    margin: 0px;   
    margin-top: 20px;
    font-weight: 900;
    color: #333133;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 30px;
`;

const LogoDescription = styled.span`
    color: #7a767a;
    font-size: 20px;
`;

interface Props{
    company: string;    
 }

const LogoComponent:React.FC<Props> = ({ company })=> (
     <>
        <LogoTitle>Rocket Desert</LogoTitle>
        <LogoDescription>{ company }</LogoDescription>
    </>
)

export default LogoComponent;