import {  useState } from 'react';
import '../login/styles/login.css';
import { useNavigate } from 'react-router-dom';
import InputTextComponent from '../../../components/input/InputText.component';
import { resetPassword } from '../../../services/auth.service';
import { InfoMessageComponent, MessageTypes } from '../../../components/InfoMessage/infoMessage.component';
import IndexLayout from "../../../layouts/index/IndexLayout";
import LoginButtonComponent from "../../../components/Buttons/LoginButton.component";
import styled from 'styled-components';

const Title = styled.h1`
  font-size: 22px;
  color: #1a1c1b;
`;

const LinkSpan = styled.span` 
  cursor: pointer;
`;

function ResetPasswordPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');  
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  
  const handleSubmit = async () => { 
    
    if(!email) {
      setError(true);
      return;
    }

    await resetPassword(email,'app')
        .then(() => showSuccessMessage())        
        .catch(() =>  showErrorMessage());    
  }

  const showSuccessMessage = () => {  
    setError(false);
    setSuccess(true);
  };

  const showErrorMessage = () => {  
    setError(true);
    setSuccess(false);
  };

  const resetMessages = () => {  
    setError(false);
    setSuccess(false);
  };

  const handleEmailChange = (value: string) => {
    setEmail(value);
    resetMessages();
  };

  return (
    
      
      <IndexLayout>
       
       <div className="Texthead">
        <Title>Reset your password</Title>        
      </div>
      <div className="responsive-head">
        <Title>Reset your password</Title>
      </div>
      <div className="form">        
        <InputTextComponent onChildStateChange={handleEmailChange}  type="text" placeholder="type your email" />                
        <div>
        {error ? <InfoMessageComponent text='Password reset failed. Please enter valid email' type={MessageTypes.Error} /> : null}
        {success ? <InfoMessageComponent text='We have e-mailed your password reset link, Please check your email!' type={MessageTypes.Success} /> : null}
        </div>

        <div className="loginButton">
          <LoginButtonComponent
            text="Send password reset link"
            color="gray"
            textColor="#fff"
            onClick={handleSubmit}
          />
        </div>
        <div className="forgotPassword">
          <div> Already have an account? <LinkSpan onClick={() => navigate('/')}>Log in</LinkSpan> to your account </div>
        </div>                       
      </div>          
          
     </IndexLayout>    

  );
}

export default ResetPasswordPage;
