import SymbolBox from '../SymbolBox/SymbolBox.component'
import { useNavigate } from 'react-router-dom';
import './styles/ProfileIcon.style.css'

interface ProfileIconProps{
    symbol: string; 
 } 

function ProfileIconComponent ({ symbol} : ProfileIconProps){
  const navigate = useNavigate();
  const handleLogout= () => {
    localStorage.clear();
    navigate('/');        
  }
  return (
  <>
  <div className="user-profile">
                    <div><SymbolBox key="user-profile" symbol={symbol} color="#5d4dab" /></div>
                    <div className='menu'>               
                      <div className='menu-item' onClick={() => navigate('/user/security')}>Security</div>
                      <div className='menu-item' onClick={handleLogout}>Sign out</div>
                    </div> 
      </div>
  </>);
}

export default ProfileIconComponent;
