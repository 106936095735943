import { Navigate } from "react-router-dom";
import useAuthorization from "./hooks/useAuthorization";

const ProtectedRoute = ({children} : any) => {
    const storedData = localStorage.getItem('user');
    const isAuthorized = storedData ? true : false;
   
    
    const isAuthor = useAuthorization();
  
    if (!isAuthor) {
      return null;
    }
     if (!isAuthorized) {
        return <Navigate to="/" replace />
    }
    return children;
}
export default ProtectedRoute;