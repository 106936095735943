import styled from "styled-components"

const Button = styled.div<{bgColor?: string, txtColor?: string, widthPixel?: number}>`
lign-items: center;
background-clip: padding-box;
background-color: #fa6400;
border: 1px solid transparent;
border-radius: 0.25rem;
box-shadow: rgb(0 0 0 / 2%) 0 1px 3px 0;
box-sizing: border-box;
color: #fff;
cursor: pointer;
display: inline-flex;
font-family: system-ui,-apple-system,system-ui,"Helvetica Neue",Helvetica,Arial,sans-serif;
font-size: 16px;
font-weight: 600;
justify-content: center;
line-height: 1.25;
margin: 0;
min-height: 3rem;
padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
position: relative;
text-decoration: none;
transition: all 250ms;
user-select: none;
-webkit-user-select: none;
touch-action: manipulation;
vertical-align: baseline;
width: ${props => props.widthPixel ? props.widthPixel + 'px' : '100%'};
background-color: ${props => props.bgColor ? props.bgColor : '#e3e1e1'};
color: ${props => props.txtColor ? props.txtColor : '#3b3a39'};
`;

interface Props{
    text: string;
    color?: string;
    textColor?: string;
    widthPixel?: number;
    onClick?: React.MouseEventHandler;
 }

 const LoginButtonComponent:React.FC<Props> = ({ text, color, textColor, widthPixel, onClick })=> (
    <>
      <Button onClick={onClick} bgColor={color} txtColor={textColor} widthPixel={widthPixel}>
         { text }
      </Button>
    </>
  )

export default LoginButtonComponent;