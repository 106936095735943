import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LoginPage from "./pages/account/login/login.page";
// import WelcomePage from "./pages/account/welcome.page";
// import PasswordPage from "./pages/account/password.page";
// import RecoveryPasswordPage from "./pages/account/recoveryPassword.page";
import TeamsPage from "./pages/team/team.page";
import BoardingPage from "./pages/boarding/boarding.page";
import ProtectedRoute from './ProtectedRoute';
import BoardingUserVerifyPage from "./pages/boarding/boardingUserVerify.page";
import UserVerifyPage from "./pages/user/userVerify.page";
import BoardingStapPage from "./pages/boarding/boarding-step-details.page";
import QuizPage from "./pages/quiz/quiz.page";
import UserSecurityPage from "./pages/user/userSecurity.page";
import ResetPasswordPage from './pages/account/resetPassword/resetPassword.page'
import SetPasswordPage from "./pages/account/resetPassword/setPassword.page";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LoginPage />,
  }, 
  {
    path: "/login",
    element: <LoginPage />,
  }, 
  {
    path: "/password/reset",
    element: <ResetPasswordPage />
  },  
  {
    path: "/password/reset/:token",
    element: <SetPasswordPage/>
  },  
  {
    path: "/team",
    element: <ProtectedRoute><TeamsPage /></ProtectedRoute>    
  },
  {
    path: "/boarding",
    element: <ProtectedRoute><BoardingPage /></ProtectedRoute>,
  },
  {
    path: "/boarding/step/:id",
    element: <ProtectedRoute><BoardingStapPage /></ProtectedRoute>,
  },  
  {
    path: "/quiz/:id",
    element: <ProtectedRoute><QuizPage /></ProtectedRoute>,
  },  
  {
    path: "/boarding/verify/:token",
    element: <BoardingUserVerifyPage />,
  }, 
  {
    path: "/user/verify/:token",
    element: <UserVerifyPage />,
  },
  {
    path: "/user/security",
    element: <UserSecurityPage />,
  }  
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
    <RouterProvider router={router} />
);

reportWebVitals();
