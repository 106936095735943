import * as React from "react";
import DashboardLayout from "../../layouts/dashboard/DashboardLayout";
import styled from "styled-components";
import { checkAssessmentScore, getTaskAssessments } from "../../services/task.service";
import {useState} from "react";
import { useNavigate, useParams } from "react-router-dom";
import  Button from '../../components/Buttons/CreateButton.component';
import LinkButton from '../../components/LinkButton/LinkButton.component'

const InputForm = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  min-width: 50%;
`;

const Total = styled.div`
  padding: 5px 3px;
  font-size: 14px;
  background-color: #5156BE;
  border-radius:15px;
  color: #fff;
  width: 120px;
  text-align: center;
`;

const Answer = styled.div`
    background-color: #E7E9EB;    
    margin: 7px 0;
    padding: 10px;    
    cursor: pointer;
    width: 50%;
    font-size: 14px;   
    &:hover{
        background-color: lightgray;
    }
`;

const ContentBox = styled.div`
    height:fit-content;    
    display: flex;
    flex-direction: column;        
    background: #fff;   
    padding: 30px 15px;
    border: 1px solid rgba(0,0,0,.08);
`;

const ProgressBar = styled.div `
    width: 250px;
    height: 20px;
    background-color: #f0f0f0;
    border-radius: 15px;
    margin-top: 5px;
    margin-left:-3px;   
 `;
  
  const Progress = styled.div<{ progress: number, threshold: number }> `
    height: 100%;
    background-color: ${p => ( p.progress < p.threshold)? '#cc0000' : '#4caf50'};
    width: ${p => p.progress}%;
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
`;

const ScoreStatus = styled.div<{ status: string }> `   
    color: ${p => ( p.status === 'Not Passed')? '#cc0000' : '#4caf50'};    
`;

const Results = styled.div`
  padding: 15px 20px;
  display: flex; 
  flex-direction: column; 
  justify-content:center;
  gap:15px;
  font-size: 14px;
  background-color: #5156BE;
  border-radius:10px;
  color: #fff;  
  width: 250px;
  margin-top: 10px;
`;

const Result = styled.div`
  display: flex;  
  width: 100%;
  
`;

const ResultTitle = styled.div`
   font-size: 16px;
   font-weight: bold;
`;
const ResultValue = styled.div`
    font-size: 18px;
    align-self: end;
    margin-left:auto;
    font-weight: bold;
`;

const ResultsHeader = styled.div`
  
  display: flex;
  flex-direction: row;
  gap: 5px;
  font-weight:bold;
  font-size: 18px; 
`;

const ActionsForm = styled.div`
  display: flex;
  flex-direction: row;  
  gap: 15px;
  margin-top: 15px;
  align-items: center;
`;

const QuizPage: React.FunctionComponent = () => {
  const navigate = useNavigate();
  
    const [result, setResult] = useState<any>({
        score: 7,
        status: 'Not Passed',
        percentageScore: 70,
        threshold: 80
      });        
    const [assessments, setAssessments] = useState<any[]>([]);   
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [showResult, setShowResult] = useState(false);
    const [checkedAnswers, setCheckedAnswers] = useState<any[]>([]);

    let { id } = useParams();   
    
    React.useEffect(() => {      
        async function getAssessments() {            
            var response = await getTaskAssessments(String(id));                
            setAssessments(response.assessments);            
        }
        getAssessments();        
    },[id]);

    const handleAnswerButtonClick = async (question: string, answer: string) => {
       const newArray = [...checkedAnswers,{question, answer}]   
       setCheckedAnswers(newArray);
        
        const nextQuestion = currentQuestion + 1;
        if(nextQuestion < assessments.length){
            setCurrentQuestion(nextQuestion);
        }
        else{
            const results = await checkAssessmentScore(String(id), checkedAnswers);
            setShowResult(true);
            setResult(results);                        
        }
    }

    const startQuiz = () => {
        setCheckedAnswers([]);   
        setCurrentQuestion(0); 
        setShowResult(false);
    }

    return (
    <>
    <DashboardLayout>
        <ContentBox>
        {(assessments && assessments.length) ? (
       <>       
       {(showResult)? 
            <>
            <ResultsHeader>
               Quiz result: <ScoreStatus status={result.status}> {result.status}</ScoreStatus>
            </ResultsHeader>
            <Results>                     
                <Result>
                    <ResultTitle>
                        <ResultTitle>Score {result.percentageScore}%</ResultTitle> 
                        <ProgressBar>
                              <Progress progress={result.percentageScore} threshold={result.threshold} >                        
                        </Progress>
                    </ProgressBar>
                    </ResultTitle>                     
                </Result>                              
                <Result>
                    <ResultTitle>Correct answers</ResultTitle> 
                    <ResultValue>{result.score} of {assessments.length}</ResultValue>                  
                </Result>
                <Result>
                    <ResultTitle>Threshold</ResultTitle> 
                    <ResultValue>{result.threshold}%</ResultValue>                  
                </Result>
                                
            </Results>
            <ActionsForm>
                {(result.status === 'Passed')? <Button text="Start quiz again"  color="#5156BE" textColor="#fff" onClick={startQuiz} /> : null }
                <LinkButton text="back to details" onClick={() => { navigate(`/boarding/step/${id}`); }} />
            </ActionsForm>
            </>   
            : 
                <><Total>Question {currentQuestion + 1} / {assessments.length}</Total>
               <InputForm>
                <h3>{assessments[currentQuestion].question}</h3>
                {assessments[currentQuestion].answers.map((answer: string, index: number) => (
                  <Answer key={index} onClick={() => handleAnswerButtonClick(assessments[currentQuestion].question, answer)}>{index + 1}. {answer} </Answer>
                ))}          
              </InputForm> </>}
        
        </>)
       : <div>There is no any assassments assigned to this step</div>}
       </ContentBox>
       </DashboardLayout>
    </>
  );
};

export default QuizPage;
