import { apiClient } from "../infrastructure/api/apiClient";

export const joinCompany = (payload: any) => {
  return apiClient.post("/boarding/activate-user", payload);
};

export const verify = (token: string) => {
  return apiClient
    .get(`/boarding/verify-token?token=${token}`)
    .catch(function (error) {
      console.log(error.response.data);
      console.log(error.response.status);
      return null;
    });
};
