import axios, {AxiosInstance} from 'axios';

export const apiClient: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  timeout: 12000
});

const retrieveUserFromLocalStorage = (): { accessToken: string } | null => {
  const userStr: string | null = localStorage.getItem("user");
  if (userStr) {
    return JSON.parse(userStr);
  }
  return null;
}

apiClient.interceptors.request.use((config) => {
  const user: { accessToken: string } | null = retrieveUserFromLocalStorage();
  config.headers.Authorization = user?.accessToken ? `Bearer ${user.accessToken}` : '';
  return config;
});

const handleUnauthorizedError = (error: any) => {
  if (error.response && error.response.status === 401) {
    localStorage.removeItem('user');
    throw new Error('Unauthorized');
  }
  return Promise.reject(error);
}

apiClient.interceptors.response.use(response => {
  return response;
}, handleUnauthorizedError);