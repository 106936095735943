import * as React from "react";
import { useState } from "react";
import { useSearchParams } from 'react-router-dom';
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { checkResetPasswordToken, setPassword } from "../../../services/auth.service";
import InputTextComponent from "../../../components/input/InputText.component";
import LoginButtonComponent from "../../../components/Buttons/LoginButton.component";

import IndexLayout from "../../../layouts/index/IndexLayout";
import {
  InfoMessageComponent,
  MessageTypes,
} from "../../../components/InfoMessage/infoMessage.component";
import PasswordStrengthComponent from "../../../components/PasswordStrength/PasswordStrength.components";
import { PasswordStrengthStatuses } from "../../../components/PasswordStrength/PasswordStrength.components";


const FormWrapper = styled.div`
    width: 400px;
    text-align: left;
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;
  text-align: left;
`;

const SetPasswordPage: React.FunctionComponent = () => {
  let { token } = useParams();
  const [searchParams] = useSearchParams();
  const returnUrl: any =  searchParams.get("returnurl");
  const accessToken: any = token || null; 
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState(''); 
  const [isLinkValid, setIsLinkValid] = useState(true);
  const [showError, setShowError] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordStrengthStatus, setPasswordStrengthStatus] =
    useState<PasswordStrengthStatuses>();  


  React.useEffect(() => {      
      async function checkToken() {            
          await checkResetPasswordToken(accessToken)
          .then(() => setIsLinkValid(true))
          .catch(() => setIsLinkValid(false));              
      }
      checkToken();
      
  },[accessToken]);

  const handleSubmit = async () => {
      
    hideErrorMessage();
    hideSuccessMessage();  

    if(!newPassword || !confirmPassword) 
      {     
        showErrorMessage('All fields is required!');
        return;
      }

    if(newPassword !== confirmPassword) 
    {     
      showErrorMessage('Password does not match!');
      return;
    }
    
    if(passwordStrengthStatus !== PasswordStrengthStatuses.STRONG)
    {
      showErrorMessage('You does not set a strong password!');
      return;
    }
     
      await setPassword(accessToken, newPassword) 
      .then( () => setShowSuccess(true))
      .catch(() => showErrorMessage('Password setup failed!'));
  }


  const handlePasswordChange = (value: any) => {
    setNewPassword(value);
    hideErrorMessage();
  };

  const handlePasswordConfirmChange = (value: any) => {    
    setConfirmPassword(value);
    hideErrorMessage();
  };  

  const showErrorMessage = (message: string) => {
    setErrorMessage(message);
    setShowError(true);
  }

  const hideErrorMessage = () => {
    setErrorMessage('');
    setShowError(false);
  }

  const hideSuccessMessage = () => {
    setShowSuccess(false);
  }

  const handlePasswordStrengthCheck = async (status: PasswordStrengthStatuses ) => { 
    console.log(status);
    setPasswordStrengthStatus(status);
  }    

  return (
    <>
      <IndexLayout>
        {isLinkValid ? <>
          {(showSuccess) ? <InfoMessageComponent text='Password was updated successfully' type={MessageTypes.Success} /> 
           : <FormWrapper>
            <h2>Set your new password</h2>                                  
              <InputTextComponent onChildStateChange={handlePasswordChange}  placeholder="Password" type="password"/>              
              { newPassword ? <PasswordStrengthComponent password={newPassword} onScoringFinished={handlePasswordStrengthCheck}/> : null }
              <InputTextComponent onChildStateChange={handlePasswordConfirmChange}  placeholder="Password confirmation" type="password" />                  
              {showError ? <InfoMessageComponent text={errorMessage} type={MessageTypes.Error} /> : null}                 
              
              <ButtonWrapper>
               <LoginButtonComponent
                onClick={handleSubmit}
                color="gray"
                textColor="white"
                text="Reset password"/>
            </ButtonWrapper>
          </FormWrapper> }
          </> : 
          <>          
            <InfoMessageComponent text='Password reset link is not valid!' type={MessageTypes.Error} />                      
          </>  }
          <div className="forgotPassword">
              {(returnUrl)?
              <div> Back to  <a href={returnUrl}>Log in</a> page </div>
              : null}
          </div>
      </IndexLayout>
    </>
  );
};

export default SetPasswordPage;
