import "./styles/boarding-workflow.css";
import {
  workflowItemBadgeColorMapper,
  workflowItemStatusMapper,
  workflowItemHedaerStyleMapper
} from "./boarding-workflow.service";
import { WorkflowItemStatuses } from "./enums/WorkflowItemStatuses.enum";
import { convertDate } from "../../../utils/DateConverter";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import  TrainingIcon from '../../../assets/svg/training.svg'


const TrainingInfo = styled.div`
    display: flex;
    align-items: center;
    font-style: italic;
    color: gray;
    font-size: 14px;
`;

const TrainingInfoIcon = styled.img`
height: 35px;
width: 35px;;
`;


interface Props {
  id: string;
  title: string;
  description: string;
  status: WorkflowItemStatuses;
  dueDate: string;
  type: string;
}
const BoardingWorkflowItem: React.FunctionComponent<Props> = ({
  id,
  title,
  description, 
  status,
  dueDate,
  type
}) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="workflow-item-wrapper">
        <div className="workflow-item-icon">
          <span className={`badge  ${workflowItemBadgeColorMapper(status)}`}>
            <span>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
                data-svg="check"
              >
                <polyline
                  fill="none"
                  stroke="#fff"
                  strokeWidth="1.1"
                  points="4,10 8,15 17,4"
                ></polyline>
              </svg>
            </span>
          </span>
        </div>
        <div className="workflow-item-content" onClick={() => { navigate(`/boarding/step/${id}`)} }>
          <div className={workflowItemHedaerStyleMapper(status)}>
            <div className="item-estimate-date">{convertDate(dueDate)}</div>
            <div className="item-status">
              Status: {workflowItemStatusMapper(status)}
            </div>
          </div>
          <div className="workflow-item-body">
            <div className="description">{(description)? description : title }</div>         
          </div>
          {(type === 'DOCUMENTATION_TRAINING' && status !== 'COMPLETED') ? 
          <TrainingInfo>
            <TrainingInfoIcon src={TrainingIcon} alt="Learning" />
            <span>Your action is required to complete this step! click for details</span>
          </TrainingInfo>: null}
        </div>
      </div>
    </>
  );
};

export default BoardingWorkflowItem;
