import { apiClient } from "../infrastructure/api/apiClient";

export const getPublicContent = () => {
  return apiClient.get("/all");
};

export const getUserBoard = () => {
  return apiClient.get("/user");
};

export const getModeratorBoard = () => {
  return apiClient.get("/mod");
};

export const getAdminBoard = () => {
  return apiClient.get("/admin");
};

export const activateUser = (token: string, password: string) => {
  return apiClient.post("/user/activate-user", {
    token,
    password,
  });
};

export const verify = (token: string) => {
  return apiClient
    .get(`/user/verify-invitation?token=${token}`)
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      console.log(error.response.data);
      console.log(error.response.status);
      return null;
    });
};

export const joinCompany = (payload: any) => {
  return apiClient.post("/user/activate-user", payload);
};

export const changePassword = async (currentPassword: string, newPassword: string) => {
  return await apiClient.post("/user/change-password", {
    currentPassword,
    newPassword
  }).then((response) => {
    return response;
  });
};
