import * as React from "react";
import DashboardLayout from "../../layouts/dashboard/DashboardLayout";
import Team from "../../features/team/team";
import TeamPerson from "../../features/team/team-person";
import { getTeamMembers } from "../../services/team.service";
const TeamPage: React.FunctionComponent = () => {

  const [teamDetails, setTeamDetails] = React.useState<any>();
  React.useEffect(() => {
    async function getTeam() {
        const teamDetails = await getTeamMembers();        
        setTeamDetails(teamDetails);
    }
    getTeam();
}, []);
  return (
    <>
      <DashboardLayout>
        <Team showSearch={false} title = { teamDetails ? teamDetails.name : null }>
            {teamDetails && teamDetails.members.length ? teamDetails.members.map((item: any) => {
                return (
                    <TeamPerson
                        firstName={item.firstName}
                        lastName={item.lastName}
                        position={item.position}
                        key={item.id}
                    />
                )
            }) : null }
        </Team>
      </DashboardLayout>
    </>
  );
};

export default TeamPage;
