import * as React from "react";
import styled from "styled-components";

const WorkFlowWrapper = styled.div`
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: flex-start;
  justify-content: flex-start;
  margin-top: 20px;
`;

const WorkFlowItemsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-direction: column;
  width: 100%;
`;
interface Props {
  children?: React.ReactNode;
}

const BoardingWorkflow: React.FunctionComponent<Props> = ({ children }) => {
  return (
    <>
      <WorkFlowWrapper>
        <WorkFlowItemsWrapper>{children}</WorkFlowItemsWrapper>
      </WorkFlowWrapper>
    </>
  );
};

export default BoardingWorkflow;
